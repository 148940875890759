import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewDirective, ViewsDirective } from './views.directive';
import { ResourceDirective, ResourcesDirective } from './resources.directive';
import { HeaderRowDirective, HeaderRowsDirective } from './headerrows.directive';
import { ScheduleComponent } from './schedule.component';
/**
 * NgModule definition for the Schedule component.
 */
export class ScheduleModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule],
    declarations: [
        ScheduleComponent,
        ViewDirective,
        ViewsDirective,
        ResourceDirective,
        ResourcesDirective,
        HeaderRowDirective,
        HeaderRowsDirective
    ],
    exports: [
        ScheduleComponent,
        ViewDirective,
        ViewsDirective,
        ResourceDirective,
        ResourcesDirective,
        HeaderRowDirective,
        HeaderRowsDirective
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ScheduleModule_tsickle_Closure_declarations() {
/** @type {?} */
ScheduleModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ScheduleModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
