import { Directive, ViewContainerRef, ContentChildren, ContentChild } from '@angular/core';
import { ComplexBase, ArrayBase, setValue } from '@syncfusion/ej2-angular-base';
import { Template } from '@syncfusion/ej2-angular-base';


let /** @type {?} */ input: string[] = ['option', 'template'];
let /** @type {?} */ outputs: string[] = [];
/**
 * `e-header-rows` directive represent a header rows of the Schedule. 
 * It must be contained in a Schedule component(`ejs-schedule`). 
 * ```html
 * <ejs-schedule>
 *   <e-header-rows>
 *    <e-header-row option='Week'></e-header-row>
 *    <e-header-row option='Date'></e-header-row>
 *   </e-header-rows>
 * </ejs-schedule>
 * ```
 */
export class HeaderRowDirective extends ComplexBase<HeaderRowDirective> {
public directivePropList: any;
/**
 * It defines the header row type, which accepts either of the following values. 
 * * Year 
 * * Month 
 * * Week 
 * * Date 
 * * Hour
 * \@default null
 */
public option: any;
/**
 * Template option to customize the individual header rows. It accepts either the string or HTMLElement as template design 
 *  content and parse it appropriately before displaying it onto the header cells. The field that 
 *  can be accessed via this template is `date`.
 * \@default null
 */
@Template()
    public template: any;
/**
 * @param {?} viewContainerRef
 */
constructor(private viewContainerRef:ViewContainerRef) {
        super();
        setValue('currentInstance', this, this.viewContainerRef);
        this.registerEvents(outputs);
        this.directivePropList = input;
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'e-header-rows>e-header-row',
    inputs: input,
    outputs: outputs,    
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ViewContainerRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'template': [{ type: ContentChild, args: ['template', ] },],
};
}

function HeaderRowDirective_tsickle_Closure_declarations() {
/** @type {?} */
HeaderRowDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
HeaderRowDirective.ctorParameters;
/** @type {?} */
HeaderRowDirective.propDecorators;
/** @type {?} */
HeaderRowDirective.prototype.directivePropList;
/**
 * It defines the header row type, which accepts either of the following values. 
 * * Year 
 * * Month 
 * * Week 
 * * Date 
 * * Hour
 * \@default null
 * @type {?}
 */
HeaderRowDirective.prototype.option;
/**
 * Template option to customize the individual header rows. It accepts either the string or HTMLElement as template design 
 *  content and parse it appropriately before displaying it onto the header cells. The field that 
 *  can be accessed via this template is `date`.
 * \@default null
 * @type {?}
 */
HeaderRowDirective.prototype.template;
/** @type {?} */
HeaderRowDirective.prototype.viewContainerRef;
}

/**
 * HeaderRow Array Directive
 */
export class HeaderRowsDirective extends ArrayBase<HeaderRowsDirective> {
constructor() {
        super('headerrows');
    }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
    selector: 'ejs-schedule>e-header-rows',
    queries: {
        children: new ContentChildren(HeaderRowDirective)
    },
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function HeaderRowsDirective_tsickle_Closure_declarations() {
/** @type {?} */
HeaderRowsDirective.decorators;
/**
 * @nocollapse
 * @type {?}
 */
HeaderRowsDirective.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
