import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewDirective, ViewsDirective } from './views.directive';
import { ResourceDirective, ResourcesDirective } from './resources.directive';
import { HeaderRowDirective, HeaderRowsDirective } from './headerrows.directive';
import { ScheduleComponent } from './schedule.component';
import { ScheduleModule } from './schedule.module';
import {Day, Week, WorkWeek, Month, Year, Agenda, MonthAgenda, TimelineViews, TimelineMonth, TimelineYear, Resize, DragAndDrop, ExcelExport, ICalendarExport, ICalendarImport, Print} from '@syncfusion/ej2-schedule';


export const /** @type {?} */ DayService: ValueProvider = { provide: 'ScheduleDay', useValue: Day};
export const /** @type {?} */ WeekService: ValueProvider = { provide: 'ScheduleWeek', useValue: Week};
export const /** @type {?} */ WorkWeekService: ValueProvider = { provide: 'ScheduleWorkWeek', useValue: WorkWeek};
export const /** @type {?} */ MonthService: ValueProvider = { provide: 'ScheduleMonth', useValue: Month};
export const /** @type {?} */ YearService: ValueProvider = { provide: 'ScheduleYear', useValue: Year};
export const /** @type {?} */ AgendaService: ValueProvider = { provide: 'ScheduleAgenda', useValue: Agenda};
export const /** @type {?} */ MonthAgendaService: ValueProvider = { provide: 'ScheduleMonthAgenda', useValue: MonthAgenda};
export const /** @type {?} */ TimelineViewsService: ValueProvider = { provide: 'ScheduleTimelineViews', useValue: TimelineViews};
export const /** @type {?} */ TimelineMonthService: ValueProvider = { provide: 'ScheduleTimelineMonth', useValue: TimelineMonth};
export const /** @type {?} */ TimelineYearService: ValueProvider = { provide: 'ScheduleTimelineYear', useValue: TimelineYear};
export const /** @type {?} */ ResizeService: ValueProvider = { provide: 'ScheduleResize', useValue: Resize};
export const /** @type {?} */ DragAndDropService: ValueProvider = { provide: 'ScheduleDragAndDrop', useValue: DragAndDrop};
export const /** @type {?} */ ExcelExportService: ValueProvider = { provide: 'ScheduleExcelExport', useValue: ExcelExport};
export const /** @type {?} */ ICalendarExportService: ValueProvider = { provide: 'ScheduleICalendarExport', useValue: ICalendarExport};
export const /** @type {?} */ ICalendarImportService: ValueProvider = { provide: 'ScheduleICalendarImport', useValue: ICalendarImport};
export const /** @type {?} */ PrintService: ValueProvider = { provide: 'SchedulePrint', useValue: Print};
/**
 * NgModule definition for the Schedule component with providers.
 */
export class ScheduleAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, ScheduleModule],
    exports: [
        ScheduleModule
    ],
    providers:[
        DayService,
        WeekService,
        WorkWeekService,
        MonthService,
        YearService,
        AgendaService,
        MonthAgendaService,
        TimelineViewsService,
        TimelineMonthService,
        TimelineYearService,
        ResizeService,
        DragAndDropService,
        ExcelExportService,
        ICalendarExportService,
        ICalendarImportService,
        PrintService
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ScheduleAllModule_tsickle_Closure_declarations() {
/** @type {?} */
ScheduleAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ScheduleAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
